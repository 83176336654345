<template>
    <LiefengContent>
        <template v-slot:title>52个示范社区发布数</template>
        <template v-slot:toolsbarRight>
            <DatePicker v-model="yearOfMonth" type="month" transfer placeholder="请选择日期" style="width: 200px" format="yyyy-MM" @on-change="changeDate"/>
            <Button type="primary" @click="getList()">查询</Button>
            <Button type="info" @click="resetBtn">重置</Button>
            <Button type="info" @click="exportExcel">导出</Button>
        </template>
        <template v-slot:contentArea>
            <LiefengTable :talbeColumns="talbeColumns" :tableData="tableData" :total="total" :loading="loading" :fixTable="true" :hidePage="true"></LiefengTable>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
export default {
    components: {
        LiefengContent,
        LiefengTable,
    },
    data() {
        return {
            loading: false,
            //表头列表
            talbeColumns: [
                {
                    title: "区",
                    key: "zoneName",
                    align: "center",
                    minWidth:100
                },
                {
                    title: "街镇",
                    key: "streetName",
                    align: "center",
                    minWidth:100
                },
                {
                    title: "社区",
                    key: "communityName",
                    align: "center",
                    minWidth:100
                },
                {
                    title: "社区发布总数",
                    key: "communityInfo",
                    align: "center",
                    minWidth:100
                },
                {
                    title: "村居委发布数",
                    key: "orgInfo",
                    align: "center",
                    minWidth:100
                },
                {
                    title: "居务公开发布数",
                    key: "publicInfo",
                    align: "center",
                    minWidth:100
                },
                {
                    title: "党务工作信息数",
                    key: "workInfo",
                    align: "center",
                    minWidth:100
                },
            ],
            tableData: [],
            total: 0,
            currentPage: 1,
            pageSize: 20,
            yearOfMonth: "",
        }
    },
    created() {
        this.yearOfMonth = this.$core.formatDate(new Date(), "yyyy-MM")
        this.getList()
    },
    methods: {
        // 重置
        resetBtn(){
            this.yearOfMonth = ''
            this.getList()
        },
        // 修改时间
        changeDate(val){
            this.yearOfMonth = this.$core.formatDate(new Date(val), "yyyy-MM")
            console.log(this.yearOfMonth);
        },
        // 导出按钮
        exportExcel() {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "<p>您正在导出当前页的数据，是否继续？</p>",
                onOk: () => {
                    let tHeader = ["区", "街镇", "社区", "社区发布总数", "村居委发布数", "居务公开发布数", "党务工作信息数"]
                    let filterVal = ["zoneName", "streetName", "communityName", "communityInfo", "orgInfo", "publicInfo", "workInfo"]
                    this.$core.exportExcel(tHeader, filterVal, this.tableData, "52个示范社区发布数")
                },
            })
        },
        getList() {
            this.loading = true
            this.$get("/gateway/api/symanage/pc/statInfoTips/selectView", {
                yearOfMonth: this.yearOfMonth,
            }).then(res => {
                this.loading = false
                if (res && res.code == 200 && res.data.dataList) {
                    this.tableData = res.data.dataList
                    this.total = res.data.dataList.length
                } else {
                    this.tableData = []
                    this.total = 0
                }
            })
        },
    },
}
</script>
    
<style scoped lang='less'>
</style>